import styles from "./StaticRangeSelector.module.scss"
import { motion } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import config from "./config"
import React from "react"
import clsx from "clsx"

type Props = {
  onUpdate: (value: string) => void
  setSoldDelta: React.Dispatch<React.SetStateAction<string>>
}

const StaticRangeSelector: React.FC<Props> = ({ onUpdate, setSoldDelta }) => {
  const query = UseQuery()
  const [deltaList, setDeltaList] = React.useState(config)

  const handleActiveDelta = (v: string) =>
    setDeltaList((p) =>
      p.map((i) => {
        const active = i.value === v
        return { ...i, active }
      })
    )

  const onClick = (i: (typeof config)[0]) => {
    const { value } = i
    handleActiveDelta(value)
    setSoldDelta(value)
    onUpdate(value)
  }

  const handleDefaulValue = () => {
    const val = query?.sold_from_delta || "2 weeks"
    handleActiveDelta(val)
  }

  React.useEffect(() => {
    handleDefaulValue()
  }, [])

  return (
    <div className={styles.deltaContainer}>
      {deltaList.map((i) => (
        <motion.div
          className={clsx({
            [styles.deltaItem]: true,
            [styles.deltaItem_active]: i.active,
          })}
          key={`delta${i.id}`}
          onClick={() => onClick(i)}
          whileTap={{ scale: 0.95 }}
        >
          <h2>{i.text}</h2>
        </motion.div>
      ))}
    </div>
  )
}

export default StaticRangeSelector
