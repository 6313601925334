const config = [
  {
    id: 0,
    value: "2 weeks",
    text: "2 weeks",
    active: false,
  },
  {
    id: 1,
    value: "1 month",
    text: "1 month",
    active: false,
  },
  {
    id: 2,
    value: "3 months",
    text: "3 months",
    active: false,
  },
  {
    id: 3,
    value: "6 months",
    text: "6 months",
    active: false,
  },
  {
    id: 4,
    value: "1 year",
    text: "1 year",
    active: false,
  },
  {
    id: 5,
    value: "3 years",
    text: "3 years",
    active: false,
  },
  {
    id: 6,
    value: "5 years",
    text: "5 years",
    active: false,
  },
  {
    id: 7,
    value: "",
    text: "5+ years",
    active: false,
  },
]

export default config
