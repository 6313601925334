import StaticRangeSelector from "components/StaticRangeSelector/StaticRangeSelector"
import { FC, useContext, useEffect, useState } from "react"
import { FiltersContext } from "context/FiltersContext"
import { TSaleFilter } from "types/filter.types"
import styles from "./ForSaleFilter.module.scss"
import config from "./ForSaleFilter.config"
import UseQuery from "hooks/UseQuery"

type Props = {}

const ForSaleFiter: FC<Props> = () => {
  const { queryApplyProperties } = useContext(FiltersContext)
  const { listing_type } = UseQuery()
  const [memoCheckTargetValue, setMemoCheckTargetValue] = useState<string>("FOR_LEASE")
  const [soldDelta, setSoldDelta] = useState("2 weeks")

  const updateFilters = (key: string) => {
    const delta = key === "SOLD" ? soldDelta : ""
    queryApplyProperties({ listing_type: key as TSaleFilter, sold_from_delta: delta })
  }

  const updateDelta = (value: string) => queryApplyProperties({ sold_from_delta: value })

  const handleRadioCheck = (value: string) => {
    if (value) {
      setMemoCheckTargetValue(value)
      updateFilters(value)
    }
  }

  useEffect(() => {
    if (listing_type) setMemoCheckTargetValue(listing_type as string)
  }, [])

  useEffect(() => {
    setSoldDelta("2 weeks")
  }, [memoCheckTargetValue])

  return (
    <div className={styles.container}>
      <div className={styles.saleTypeWrapper}>
        {config.map((i) => (
          <div className={styles.saleTypeItem} key={`saleTypeItem${i.id}`}>
            <div className={styles.radioWrapper}>
              <input
                type="radio"
                name="saleFilter"
                checked={i.key === memoCheckTargetValue}
                id={i.key}
                onChange={() => handleRadioCheck(i.key)}
              />
              <label htmlFor={i.key}>{i.text}</label>
            </div>
            <i.icon />
          </div>
        ))}
      </div>
      {memoCheckTargetValue === "SOLD" && (
        <div className={styles.soldRangeSelector}>
          <h3>Select lookback period for sold properties:</h3>
          <StaticRangeSelector onUpdate={updateDelta} setSoldDelta={setSoldDelta} />
        </div>
      )}
      <div className={styles.offsideTypesWrapper}>
        <div className={styles.saleTypeItemOffside}>
          <input
            type="radio"
            name="saleFilter"
            id="OFF_MARKET"
            onChange={() => handleRadioCheck("OFF_MARKET")}
            checked={memoCheckTargetValue === "OFF_MARKET"}
          />
          <label htmlFor="OFF_MARKET">Off Market</label>
        </div>
      </div>
    </div>
  )
}

export default ForSaleFiter
